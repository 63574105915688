<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "IconElement",
  mixins: [layoutMixin],
  props: {
    customClass: String,
    customStyle: Object,
    hasImage: Boolean,
    icon: Array,
  },
  data() {
    return {
      fontSize: "15px",
    };
  },
  render: function(createElement) {
    var renderedIcon = document.getElementById("icon-rendered");
    this.adaptFont(renderedIcon.innerHTML);

    // text class
    var txtClass = "overflow-hidden overflow-clip flex flex-row";
    if (this.layout) {
      switch (this.layout.align) {
        case 0:
          txtClass += " justify-start text-left";
          break;
        case 1:
          txtClass += " justify-center text-center";
          break;
        case 2:
          txtClass += " justify-end text-right";
          break;
      }
      switch (this.layout.vAlign) {
        case 0:
          txtClass += " items-start";
          break;
        case 1:
          txtClass += " items-center";
          break;
        case 2:
          txtClass += " items-end";
          break;
      }
    }

    return createElement(
      "div",
      {
        class: [this.layoutClass, this.bgClass, txtClass, this.customClass],
        style: [
          this.layoutStyle,
          this.bgStyle,
          this.textStyle,
          this.customStyle,
        ],
      },
      [
        createElement("font-awesome-icon", {
          props: {
            icon: this.icon,
          },
        }),
      ]
    );
  },
};
</script>
